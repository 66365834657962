.footer {
	.footer {
		&-top {
			background: $body-color;
			color: $white;
			padding: 70px 0;

			.container {
				.container-holder {
					width: 100%;
					margin: 0;

					@include media-breakpoint-up(md) {
						width: 80%;
						margin: 0 auto;
					}
				}
			}

			.footer-heading {
				h3 {
					color: $white;
					font-weight: 400;
					font-family: $font-shadow-into;
					font-size: $h3-font-size;
					text-align: center;
				}

				p {
					font-family: $font-family-base;
					font-size: $font-size-base_sm;
					text-align: center;
				}
			}

			.footer-location {
				.container-holder {
					.column.two {
						margin-top: $spacer * 5.625;

						@include media-breakpoint-up(md) {
							margin-top: 0;
						}
					}
				}

				.location-wrapper {
					/*
					display: flex;
					align-items: center;
					*/
					margin-bottom: ($spacer * 2.5);

					.logo-img {
						margin-right: $spacer * 1.25;
					}

					.location-content {
						h3 {
							color: $white;
							font-size: $h4-font-size;
							font-weight: $font-weight-base;
						}

						p {
							margin-bottom: 0;
							color: $white;
							font-size: $font-size-base_sm;
							font-weight: $font-weight-base;
						}
					}
				}

				.opening-hours {
					font-size: $font-size-base_xxs;
					font-family: $font-family-base;
					font-weight: $font-weight-base;

					.title {
						font-family: $font-family-base;
						font-size: $h5-font-size;
						font-weight: $font-weight-bold;
						color: $white;
					}

					.text-item {
						display: flex;
						border-bottom: $border-width solid rgba(255, 255, 255, 0.4);
						margin-bottom: $spacer * 0.25;
						padding: ($spacer * 0.25) 0;
						height: 100%;
						align-items: center;
					}
				}

				.vocabulary_term_list {
					margin-top: 83px;

					li {
						>a {
							font-size: 0;

							>i {
								font-size: $font-size-base * 2.5;
							}
						}
					}
				}
			}

			.contact-info {
				margin-top: $spacer * 1.75;
				margin-bottom: $spacer * 6.25;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				@include media-breakpoint-up(md) {
					flex-direction: row;
				}

				li {
					border: $border-width solid rgba(255, 255, 255, 0.4);
					border-radius: 4px;
					box-shadow: 0px 3px 6px $box-shadow;
					padding: ($spacer * 0.75);
					color: $white;
					font-size: $font-size-base_sm;
					font-family: $font-family-base;
					font-weight: $font-weight-base;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 0;
					margin-bottom: $spacer;
					width: fit-content;

					@include media-breakpoint-up(md) {
						padding: ($spacer * 0.3) ($spacer * 1.5);
						margin-right: $spacer;
						margin-bottom: 0;
					}

					&:hover {
						border-color: $base-2;
						color: $base-2;
						cursor: pointer;

						a {
							color: $base-2;
							text-decoration: none;

							i {
								color: $base-2;
							}
						}
					}

					a {
						color: $white;

						i {
							color: $secondary;
							font-size: $font-size-base * 1.25;
							font-weight: $font-weight-bold;

							@include media-breakpoint-up(md) {
								margin-right: $spacer * 0.5;
							}
						}
					}
				}
			}
		}

		&-socket {
			background: $white;

			.container-holder {
				.column.one {
					display: flex;
					align-items: center;
					justify-content: center;
					order: 2;

					@include media-breakpoint-up(md) {
						display: flex;
						align-items: flex-end;
						justify-content: flex-start;
						order: unset;
					}
				}

				.footer-arrow {
					.link {
						position: relative;
						font-size: 0;
						display: block;

						&:hover {
							&::after {
								bottom: 18px;
							}
						}

						&::before {
							content: "";
							display: block;
							position: absolute;
							top: -65px;
							left: 50%;
							width: 60px;
							height: 60px;
							background-color: $body-color;
							border-radius: 50%;
							transform: translateX(-50%);

							@include media-breakpoint-up(sm) {
								top: -70px;
							}
						}

						&::after {
							content: "\F0AA";
							display: block;
							position: absolute;
							bottom: 15px;
							color: white;
							font-family: $font-awesome;
							font-size: $font-size-base * 1.5 !important;
							font-weight: 300;
							left: 50%;
							transform: translateX(-50%);
							transition: 0.3s;

							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 1.875 !important;
							}
						}
					}
				}
			}

			.logo {
				display: flex;
				justify-content: flex-end;
			}

			.footer_link {
				.list {
					@include media-breakpoint-up(md) {
						justify-content: center;
					}

					.list-item {
						margin-right: 0;
						text-align: center;

						@include media-breakpoint-up(md) {
							margin-right: $spacer;
							text-align: left;
						}

						.link {
							color: $body-color;
							font-size: $font-size-base_xxs;
							font-family: $font-family-base;
							font-weight: $font-weight-base;
						}
					}
				}
			}
		}
	}
}


.merken-slider.page_block.grid {
	margin-bottom: 0;
}
