&.faq_overview {
	.faq-title {
		pointer-events: none;
		padding-left: 0;

		.faq-icon {
			display: none !important;
		}

		+ .faq-items {
			display: block !important;
		}
	}
}
