/* bootstrap > theme-colors
   ========================================================================== */
$primary: #176898 !default;
$secondary: #f28b00 !default;
$body-color: #2a2c24 !default;
$gray-dark: #3c3c3b;
$blue-dark: #0e4363;

/* bootstrap > font
       ========================================================================== */
/*@font-face {
   font-family: "Noto Sans";
   src: url("~project/public/fonts/NotoSans-Light.ttf");
   font-weight: 300;
}
@font-face {
   font-family: "Noto Sans";
   src: url("~project/public/fonts/NotoSans-Regular.ttf");
   font-weight: 400;
}
@font-face {
   font-family: "Noto Sans";
   src: url("~project/public/fonts/NotoSans-Medium.ttf");
   font-weight: 500;
}
@font-face {
   font-family: "Noto Sans";
   src: url("~project/public/fonts/NotoSans-Bold.ttf");
   font-weight: 700;
}*/

@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-baloo: 'Baloo 2', cursive;
$font-noto-sans: 'Noto Sans', sans-serif;
$font-shadow-into: 'Shadows Into Light', cursive;
$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-noto-sans;

$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 1.875 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.125 !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_xs !default;

/* bootstrap > typography
      ========================================================================== */

$headings-font-family: $font-baloo;
$headings-font-weight: 700;
$headings-color: $primary;

body.default {
   overflow-x: hidden;
}
