/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 0 !default;
$body-sticky-padding: 0 !default;

/* ==========================================================================
   color
   ========================================================================== */
$base-1: #1F86C4;
$base-2: #6DC1F3;
$base-3: #b9d1e0;
$base-4: #949591;
$base-5: #EDEDF4;
$base-6: #707070;
$box-shadow: rgba($black , 0.16);

/* ==========================================================================
   content
   ========================================================================== */
$content-h1-color: $primary;
$content-h2-color: $primary;
$content-h3-color: $primary;
$content-h4-color: $primary;
$content-h5-color: $primary;
$content-h6-color: $primary;
