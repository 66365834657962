// headings
h1, h2 {
	text-transform: uppercase;
}

// img
img {
	max-width: 100%;
	height: auto;
	border-radius: 10px;
}

.pseudo-block {
	content: "";
	display: block;
	max-width: 160px;
	height: 2px;
	margin: 10px 0 25px;
	background-color: $secondary;
}

.services {
	@include media-breakpoint-up(sm) {
		background: $white;
		box-shadow: 6px 6px 12px $box-shadow;
		border: 1px solid $primary;
		border-radius: 6px;
		padding: $grid-gutter-width * 1.33;
		margin-bottom: $grid-gutter-width * 1.33;
		transform: translate(0, 60px);
		margin-left: -80px;
	}
	h2 {
		text-transform: uppercase;
	}
	h3 {
		color: $primary;
		font-family: $font-shadow-into;
		font-weight: $font-weight-base;
		max-width: 350px;
		margin-bottom: $grid-gutter-width / 3;
		position: relative;

		&::after {
			position: absolute;
			content: "";
			background-image: url("/images/map.svg");
			width: 120px;
			height: 137px;
			top: -15px;
			right: 0;

			@include media-breakpoint-up(xl) {
				right: -100px;
			}
		}
	}

	p {
		font-size: $font-size-base;
		max-width: 385px;
		@include media-breakpoint-up(sm) {
			font-size: $font-size-base_sm;
		}
	}

	.page_block.link {
		@extend .btn-primary;
	}
}

&.blog_post_overview {
	.blog_overview {
		margin-top: 35px;
	}
}

.merken-slider {
	@include media-breakpoint-down(sm) {
		flex-wrap: nowrap !important;
		justify-content: flex-start !important;
		overflow-x: scroll;
		width: 100%;
	}

	.item {
		max-width: 200px !important;
		flex: 0 0 auto !important;

		.card {
			border: none;

			.card-image {
				background: transparent;

				img {
					max-width: 100%;
				}
			}

			.card-img-overlay {
				background: transparent;
			}

			.card-body {
				display: none !important;
			}
		}
	}
}

ul.custom-list {
	list-style: none;
	padding-left: 0;

	li {
		position: relative;
		padding-left: 25px;

		&:before {
			content: "\f00c";
			font-family: "Font Awesome 5 Pro";
			display: inline-block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

// search
&.search {
	.content {
		.search-icon {
			display: none;
		}
		.searchbox {
			margin-bottom: 30px;
			.searchbox-holder {
				position: relative;
				button {
					position: absolute;
					top: 1px;
					right: 1px;
					bottom: 1px;
					border: none;
					background: $white;
					font-size: 24px;
					transition: color 0.5s;
					&:hover {
						color: #4babe4;
					}
				}
			}
		}
	}
}

// search_results
.search_results {
	.search_term {
		width: 100%;
		margin: 30px 15px;
	}
}
