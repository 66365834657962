.js-assortiment-block {
	.grid.page_block.assortiment_overview {
		.item {
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
		}
	}
}

.js-assortiment-block,
.owl-stage,
& {
	.grid.page_block.assortiment_overview,
	.owl-item,
	.page_block {
		.item {
			.card {
				overflow: visible;

				&-body {
					padding: 30px 15px 15px;
					border-radius: 0 0 5px 5px;

					.card-caption {
						display: flex;
						flex-direction: column;

						.card-title-link {
							.card-title {
								font-size: $font-size-base * 1.375 !important;
							}
						}

						.card-price-value,
						.card-subtitle {
							font-size: $h4-font-size;
							color: $primary;
							margin-bottom: $grid-gutter-width;
							&.forsale {
								color: $base-6;
								font-size: $h5-font-size;
								font-weight: 400;
							}
						}
						.card-price-value { margin-bottom: 0;}

						.card-description {
							min-height: 0;
							max-height: auto;
							margin-bottom: $grid-gutter-width;

							&-content {
								font-size: $font-size-base_sm;
							}

							&::after {
								content: none;
							}
						}
					}

					.card-buttons {
						position: relative;
						z-index: 1;
						margin-top: auto;

						.card-btn {
							@extend .btn-outline-primary;
						}
					}
				}

				&-image {
					.card-image-link {
						.card-image-caption {
							bottom: auto;

							.card-image-label {
								border-top-color: $secondary;
								background: $secondary;
								font-weight: 700;
								width: max-content;
								position: static;
								margin-top: -15px;
								margin-left: 15px;

								&::after {
									content: "";
									position: absolute;
									top: 18px;
									left: 15px;
									width: 0;
									height: 0;
									border-top-color: inherit;
									border-top-style: solid;
									border-top-width: 20px;
									border-left: solid 0 transparent;
									border-right: solid 20px transparent;
								}
							}
						}

						.card-img {
							border-radius: 5px 5px 0 0;
						}
					}
				}
			}
		}
	}
}

.main {
	.content {
		.blog-overview {
			.page_block {
				&.blog_filter {
					display: none;
				}
			}

			.page_block {
				&.grid {
					.item {

						@include make-col(12);

						.card {
							border: none;
							background: #b9d1e0;
							border-radius: 10px;

							.card-image {
								background: transparent;

								.card-img {
									border-radius: 10px 0 0 10px;
								}
							}

							.card-body {
								padding: 26px 39px;
								background: transparent;

								.card-title {
									font-size: 1.5rem !important;
									font-weight: 400;
								}

								.card-subtitle {
									order: -1;
									font-family: 'Dancing Script';
									font-weight: 100;
									font-size: 1.375rem;
									margin-bottom: 10px;
								}

								.card-description {
									margin-bottom: 0;
								}

								.card-buttons {
									text-align: right;
								}

							}
						}
					}
				}
			}
		}
	}
}

.card-prices {
	margin-bottom: 30px !important;
	.card-price {
		.card-price-value {
			&.forsale {
				text-decoration: line-through;
				margin-bottom: 0 !important;
				//opacity: 0.6;
			}
		}

		.card-price.discount {
			display: flex;
			flex-direction: column;
			.card-price-value {
				margin-bottom: 0 !important;
				color: $secondary !important;
			}
		}
	}


	.card-price-description.price-prefix {
		font-family: $font-baloo;
		font-size: 1.375rem;
	}
}
