.modal.show .modal-dialog {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, calc(-50% - 20vh));

	.modal-content {
		border: 1px solid #176898;
	}

	.modal-title {
		font-size: 1.875rem;
		color: #176898;
		font-family: Dancing Script, sans-serif;
		font-weight: 400;
	}

	.modal-body {
		border-radius: 4px;

		p {
			color: #2a2c24;
		}
	}

	button.close {
		color: #176898;
	}
}

.notice_bar,
.notice-bar {
	display: none;

	&.show {
		display: block;
	}
}

.notice-bar-content {
	background: #176898;
	@media (max-width: 600px) {
		display: flex;
		flex-flow: row nowrap;
	}
}

.notice-bar-close {
	position: absolute;
	right: 0;
	top: 0;
	@media (max-width: 600px) {
		position: relative;
		height: 1rem;
		width: 1rem;
		margin-right: 0.5rem;
		.notice-bar-close-title {
			display: none;
		}
	}
}

.notice-bar-content-description {
	text-align: center;

	p {
		color: #fff;
		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 10px;
	}

	a {
		color: #f28b00;
		font-weight: 700;

		&:hover {
			color: #fff;
		}
	}

	@media (max-width: 600px) {
		text-align: start;
		p {
			font-size: medium;
		}
	}
}

.js-notice-bar-close {
	font-size: 20px;
	margin-right: 15px;
}

.notice-bar-content {
	position: relative;
}

.notice-bar-close {
	color: #fff;
	top: 12px;

	i {
		margin-right: 5px;
	}
}
