.main {

	.home_block_1,
	.home_block_3 {
		padding: 20px 0 60px;

		@include media-breakpoint-up(sm) {
			padding: 70px 0;
		}

		.container-two-columns {
			.container-holder {
				justify-content: center;
				.column {
					.page_block.resource_image {
						img {
							border-radius: 6px;
							box-shadow: 8px 8px 0 $base-3;
						}
					}

					&.two {
						@extend .services;

						h2 {
							color: $body-color;
						}
					}
				}
			}
		}
	}

	.home_block_2 {
		margin: 15px 0 0;
		padding: 60px 0 50px;
		background: $body-color;

		.container-two-columns {
			.container-holder {
				align-items: center;

				.column.one {
					@include media-breakpoint-down(sm) {
						order: 2;
						margin-top: 20px;
					}

					h2 {
						text-transform: uppercase;
					}

					h2,
					p {
						max-width: 460px;
						color: $white;
					}

					h3 {
						color: $secondary;
						font-weight: 400;
						font-family: $font-shadow-into;
						font-size: $h3-font-size !important;
					}

					.page_block.link {
						@extend .btn;
						background: $secondary;
						border-color: $secondary;
						color: $white;

						&:hover {
							background: darken($secondary, 10%);
							border-color: darken($secondary, 10%);
							color: $white;
						}
					}
				}

				.column.two {
					.page_block.resource_image {
						img {
							border-radius: 6px;
						}
					}
				}
			}
		}
	}

	.home_block_3 {
		padding: 60px 0;

		@include media-breakpoint-up(sm) {
			padding: 70px 0;
		}

		.container-two-columns {
			.container-holder {
				.column {
					.page_block.resource_image {
						img {
							min-height: 262px;
							object-fit: cover;

							@include media-breakpoint-up(lg) {
								min-height: 392px;
							}
						}
					}

					&.two {
						@extend .services;

						h3 {
							position: relative;
							max-width: 385px;

							&::after {
								background-image: url("/images/measure.svg") !important;
								width: 135px !important;
								height: 135px !important;
							}
						}
					}
				}
			}
		}
	}

	.call-to-action {
		padding: 45px 0 75px;

		.container-default {
			.container-holder {
				text-align: center;

				h3 {
					font-weight: 400;
					font-family: $font-shadow-into;
					color: $primary;
					margin-bottom: $grid-gutter-width / 2;
				}

				p {
					font-size: $font-size-base_sm;
					max-width: 700px;
					margin: auto;
				}

				.buttom_primary {
					@extend .btn-primary;
					margin-top: $grid-gutter-width;
					border-radius: 4px !important;
					border-color: $white !important;
					box-shadow: 0px 3px 6px $box-shadow;
				}
			}
		}
	}

	.offer {
		position: relative;
		padding: 60px 0 50px;
		border-bottom: 10px solid $base-3;
		background: url("/images/offer_backgroud.jpg") no-repeat center / cover;

		&::after {
			content: "";
			position: absolute;
			background: linear-gradient(180deg, rgba($black, 0), #2a2c24);
			bottom: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.6;
			pointer-events: none;
		}

		.container-default {
			.container-holder {

				h1,
				h2,
				.page_block.link {
					color: $white;
				}

				h1 {
					font-size: $h2-font-size;
				}

				h2 {
					font-size: $h3-font-size;
					font-family: $font-shadow-into;
					font-weight: $font-weight-base;
				}

				.page_block.link {
					display: flex !important;
					justify-content: flex-end !important;
					margin-top: 20px;
					@extend .btn-secondary;
					width: max-content;
					margin-left: auto;
					color: $white !important;
					border-color: $white !important;

					@include media-breakpoint-up(sm) {
						margin-top: -80px;
					}
				}

				.slider {
					margin-top: 40px;

					@include media-breakpoint-up(sm) {
						margin-top: 100px;
					}

					.owl-stage-outer {
						overflow: hidden;
						padding-top: 15px;

						.owl-stage {
							.owl-item {
								opacity: 0;
								pointer-events: none;
							}

							.owl-item.active {
								opacity: 1;
								pointer-events: initial;
							}
						}
					}

					.owl-nav {
						left: auto;
						right: auto;
						position: relative;
						margin: 10px 0 25px;

						.owl-prev,
						.owl-next {
							position: static;
							display: inline;
							width: auto;
							color: $white;
							font-size: $font-size-base * 2.25;
						}

						.owl-prev {
							.fa-chevron-left {
								font-weight: 300;

								&::before {
									content: "\f0a8" !important;
								}
							}
						}

						.owl-next {
							margin-left: 15px;

							.fa-chevron-right {
								font-weight: 300;

								&::before {
									content: "\f0a9" !important;
								}
							}
						}
					}

					.owl-dots {
						display: none;
					}
				}
			}
		}
	}

	.content.detail {
		padding: 60px 0;

		@include media-breakpoint-up(sm) {
			padding: 80px 0;
		}

		.assortiment_detail {
			margin-top: 0;

			.info {
				.btn-back {
					text-decoration: none;
				}

				.prices {
					background: $secondary;
					border-color: $secondary;
				}

				.characteristics,
				.description {
					h3 {
						color: $primary;
						text-transform: capitalize;
						font-family: $font-shadow-into;
						font-weight: $font-weight-base;

						&::after {
							background-color: $secondary;
						}
					}

					ul {
						li {
							.label {
								opacity: 0.7;
							}
						}
					}
				}

				.description {
					margin: 30px 0 0;
				}
			}

			.info-extra {
				.contact-form {
					border: 1px solid $base-6;
					box-shadow: 6px 6px 6px rgba($black, 0.15);

					h2 {
						color: $primary;
						text-transform: capitalize;

						&::after {
							@extend .pseudo-block;
						}
					}

					.btn {
						box-shadow: 6px 6px 6px rgba($black, 0.15);
					}
				}
			}

			.info,
			.info-extra {
				margin-bottom: 0 !important;
			}
		}
	}

	.content.contact {
		padding: 60px 0;

		@include media-breakpoint-up(sm) {
			padding: 80px 0;
		}

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						h2 {
							font-family: $font-shadow-into;
							font-weight: $font-weight-base;
							color: $primary;

							&::after {
								@extend .pseudo-block;
							}
						}

						.contact-twocolumn {
							margin-bottom: 20px;
						}
					}

					&.two {
						box-shadow: 6px 6px 12px $box-shadow;
						border: 1px solid $primary;
						border-radius: 6px;
						padding: $grid-gutter-width * 1.33;
					}
				}
			}
		}
	}

	.content.info {
		padding: 60px 0;

		@include media-breakpoint-up(sm) {
			padding: 80px 0;
		}

		.container {
			.container-holder {
				h1 {
					margin-bottom: 30px;
				}
			}
		}

		.container-two-columns {
			.container-holder {
				.column {
					h2 {
						&::after {
							@extend .pseudo-block;
						}
					}
				}
			}
		}
	}

	.content.filter {
		padding: 60px 0;

		@include media-breakpoint-up(sm) {
			padding: 80px 0;
		}

		.container-two-columns {
			.container-holder {
				.column.one {
					.sidebar_filter {
						form {
							.form-group {
								h4 {
									text-transform: capitalize;
									color: $primary;

									&::after {
										@extend .pseudo-block;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog_detail,
&.blog_post_detail {
	.gallery-link img {
		border-radius: 6px;
		box-shadow: 8px 8px 0 #b9d1e0;
	}
}

.blog_detail .info .extra {
	font-family: 'Dancing Script';
	font-weight: 100;
	font-size: 1.375rem;
	margin-bottom: 10px;
	color: #176898;
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	&.bg-blue {
		padding: 6vh 0;
		background: #e8f0f5;
	}

	.container-two-columns {
		.container-holder {
			//align-items: center;

			h2 {
				&::after {
					@extend .pseudo-block;
				}
			}
			img {
				border-radius: 10px;
			}
			.owl-carousel {
				.item {
					border-radius: 10px;
				}
			}
		}
	}
}


// locations-section
// =========================================================================
.locations-section {
	margin: 60px 0;
	padding: 45px 0 30px 0;
	background: #e8f0f5;
	color: $primary;
}
