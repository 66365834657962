.header {
	position: sticky;

	&.sticky {
		position: sticky;
	}

	.header-socket {
		background: $body-color !important;

		.container-navbar {
			@include media-breakpoint-up(xl) {
				padding-left: 30px !important;
				padding-right: 30px !important;
			}

			.container-holder {
				.navbar {
					padding: 0 15px;

					@include media-breakpoint-down(lg) {
						flex-wrap: wrap !important;
						justify-content: space-evenly !important;
					}

					.logo {
						z-index: 100;
						margin: 20px auto -15px 0;

						a {
							display: block;
						}

						img {
							border-radius: 0;
							@include media-breakpoint-down(lg) {
								max-width: 190px;
							}
						}
					}

					// quick-icons
					.quick-icons {
						margin-bottom: 0;

						@media (max-width: 399px) {
							margin-top: -30px;
						}

						li {
							margin-right: 0;
							margin-left: 10px;
							font-size: 20px;

							@include media-breakpoint-up(xl) {
								margin-left: 25px;
								font-size: 24px;
							}

							a {
								color: $white;

								span {
									display: none;
								}

								&:hover {
									color: $orange;
								}
							}
						}
					}

					// searchbox
					.searchbox {
						position: relative;
						@media (max-width: 399px) {
							margin-top: -30px;
						}

						.search-icon {
							color: $white;
							font-size: 20px;

							@include media-breakpoint-up(xl) {
								font-size: 24px;
							}

							&:hover {
								color: $orange;
								cursor: pointer;
							}

							&.hide {
								display: none;
							}
						}

						.searchbox-holder {
							display: none;

							&.show {
								display: block;
								z-index: 110;
								position: absolute;
								right: 0;
								background: $gray-dark;
							}

							.form-control {
								background: transparent;
								color: $white;
								font-size: 15px;
								font-weight: 500;
								min-width: 220px;

								&:hover,
								&:focus {
									color: $white;
								}

								/*&::-webkit-input-placeholder {
									color: $white;
								}

								&:-ms-input-placeholder {
									color: $white;
								}

								&::placeholder {
									color: $white;
								}*/
							}

							button {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								width: 36px;
								height: 36px;
								border: none;
								background: none;
								color: $white;
								font-size: 24px;
								transition: color 0.5s;

								&:hover {
									color: $secondary;
								}
							}
						}
					}

					&-collapse {
						@include media-breakpoint-down(lg) {
							order: 5;
							padding: 0 !important;
						}

						display: none !important;
						flex-basis: 100% !important;

						@include media-breakpoint-up(xl) {
							display: flex !important;
							justify-content: flex-end;
							flex-basis: auto !important;
							flex-grow: 0;
						}

						.navbar-nav {
							align-items: center;

							@include media-breakpoint-down(lg) {
								display: flex;
								flex-direction: column;
								padding-left: 0;
								margin-bottom: 15px;
								margin-top: 15px;
								list-style: none;
								width: 100%;
							}

							.nav-item {
								font-size: 15px;

								@include media-breakpoint-up(xl) {
									margin-left: 5px;
								}

								@include media-breakpoint-down(lg) {
									display: block;
									width: 100%;
								}

								&.first {
									.nav-link {
										position: relative;
									}
								}

								@include media-breakpoint-down(lg) {

									&.last {
										.nav-link {
											max-width: none;
											text-align: left;
										}
									}

									&:not(:last-child) {
										border-bottom: 1px solid rgba($secondary, 0.5);
										margin-bottom: 10px;
									}
								}

								.nav-link {
									color: $white;
									font-weight: $font-weight-medium;

									@include media-breakpoint-up(xl) {
										padding: 0 16px;
									}

									&:hover {
										color: $secondary;
									}
								}
							}

							.nav-item[icon*='<i class'] {
								font-size: 0;

								> a {
									> i {
										font-size: $font-size-base;
									}
								}
							}


							.dropdown-toggle {
								position: relative;

								&::after {
									content: "\f078";
									position: absolute;
									right: 0;
									display: block;
									font-family: $font-awesome;
									font-weight: 900;
									transform: translateY(-50%);
									top: 50%;
									font-size: $font-size-base_xxs;
									border: none !important;
								}

								@include media-breakpoint-down(lg) {
									&[aria-expanded="true"] {
										&::after {
											content: "\f068";
										}
									}

									&::after {
										margin-right: 15px;
										content: "\F067";
										font-family: $font-awesome;
										font-size: $font-size-base_xs;
										font-weight: 700;
										border: none;
									}
								}
							}

							.dropdown-menu {
								@include media-breakpoint-up(xl) {
									left: -10%;
									border: 1px solid $base-5;
									border-radius: 6px;
									box-shadow: 0px 3px 6px $box-shadow;

									&:before,
									&:after {
										content: "";
										display: block;
										height: 0;
										left: 50%;
										position: absolute;
										width: 0;
									}

									&:before {
										border-left: 8px solid transparent;
										border-right: 8px solid transparent;
										border-bottom: 6px solid $base-5;
										margin-left: -5px;
										top: -6px;
										z-index: 4;
									}

									&:after {
										border-left: 6px solid transparent;
										border-right: 6px solid transparent;
										border-bottom: 5px solid $white;
										margin-left: -3px;
										top: -5px;
										z-index: 5;
									}
								}

								@include media-breakpoint-down(lg) {
									position: static !important;
									transform: none !important;
									min-width: 100%;
									border: none;
									background-color: transparent;
									box-shadow: none;
									padding: 0;
									margin: 0;

									&.show {
										margin-left: 15px;
										padding-bottom: 15px;
									}
								}

								.nav-item {
									&.first {
										.nav-link {
											&::before {
												content: none;
											}
										}
									}

									&:not(:last-child) {
										margin-bottom: 15px;
									}

									.nav-link {
										color: $white;
										font-size: $font-size-base_sm;

										@include media-breakpoint-up(xl) {
											color: $primary;
										}

										&:hover {
											color: $secondary;
										}
									}
								}
							}
						}
					}

					.site_switcher {

						@media (max-width: 399px) {
							position: absolute;
							top: 40px;
							right: 15px;
						}

						margin-left: 15px;

						.language {
							.nav-item {
								width: 21px;
								height: 21px;

								+ li {
									margin-left: 10px;
								}

								overflow: hidden;
								border-radius: 100%;
								border: 1px solid $white;

								.nav-link {
									font-size: $font-size-base * 1.125;
									line-height: 1;
									display: block;
									padding: 0;
								}
							}
						}
					}

					&.column {
						.navbar-collapse {
							display: none !important;

							&.show {
								display: flex !important;
							}

							@include media-breakpoint-down(lg) {
								padding: 0 15px;
							}

							@include media-breakpoint-up(xl) {
								display: flex !important;
								justify-content: flex-end;
							}
						}

						.navbar-toggler {
							display: flex;
							border-color: transparent;
							color: $white;
							line-height: 30px;
							margin-left: -15px;

							@include media-breakpoint-up(xl) {
								display: none;
							}

							.navbar-toggler-icon {
								background-image: none;
							}
						}
					}
				}
			}
		}
	}
}

.last > a {
	padding: 0 16px !important;
}

.navbar-nav > li.last > a {
	padding: 0 16px !important;
	background: #176898;
	transition: .3s;
	text-align: center;
	max-width: 200px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 700 !important;
	line-height: 34px;
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;

	&:hover {
		color: #FFFFFF !important;
		background: #1F86C4;
		border-color: #176898;
	}
}
