.btn {
    display: inline-flex;
    align-items: center;
    padding: 6px 25px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    color: $white;
    text-decoration: none;
	text-transform: uppercase;
    transition: 0.3s;

    &-primary {
        @extend .btn;
        color: $white;
        background: $primary;
        border-color: $primary;

        &:hover {
            color: $white;
            background: $base-1;
            border-color: $base-1;
        }
    }
    &-secondary {
        @extend .btn;
        color: $white;
        background: $secondary;
        border-color: $secondary;

        &:hover {
            color: $white;
			background: darken($secondary, 10%);
			border-color: darken($secondary, 10%);
        }
    }
    &-secondary-transparent {
        @extend .btn;
        color: $white;
        border-color: rgba($white, 0.5);

        &:hover {
            color: $base-2;
            border-color: $base-2;
        }
    }
    &-outline-primary {
        @extend .btn;
        color: $primary;
        border-color: $primary;
        background: transparent;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
    &-outline-secondary {
        @extend .btn;
        color: $white;
        border-color: $secondary;
        background: transparent;

        &:hover {
            background: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }
}
