.eyecatcher {
	.eyecatcher-container {
		max-width: none;

		.container-holder {
			.owl-carousel {
				.owl-stage-outer {
					.owl-stage {
						.owl-item {
							.item {
								position: relative;

								&::after {
									content: "";
									position: absolute;
									background: linear-gradient(109deg, rgba($primary, 1) 0%, rgba($primary, 1) 7.5%, rgba($primary, 0) 50%, rgba($primary, 0) 100%);
									top: 0;
									right: 0;
									bottom: 0;
									left: 0;
									height: 100%;
								}
							}
						}
					}
				}

				.owl-dots {
					display: none;
				}
			}
		}
	}

	.banner_content {
		position: absolute;
		top: 50%;
		left: 5%;
		transform: translateY(-50%);
		z-index: 1;
		max-width: 420px;
		@include media-breakpoint-down(xs) {
			left: 30px;
			right: 30px;
		}

		p {
			color: $white;
			font-size: 30px;
			line-height: 1;
			font-family: $font-shadow-into;

			@include media-breakpoint-up(xl) {
				font-size: 50px;
			}

			&::after {
				content: "";
				display: block;
				width: 175px;
				height: 15px;
				background: url('/images/line-orange.svg');
				background-size: cover;

				@include media-breakpoint-up(xl) {
					width: 349px;
					height: 30px;
				}
			}
		}

		.link {
			margin-top: 10px;
			&:first-of-type {
				margin-right: 10px;
			}
			&.btn-outline-secondary {
				border-width: 2px;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}
}
