&.assortiment_overview {
	.content .column.one {
		@include make-col(3);

		@include media-breakpoint-down(sm) {
			flex: none;
			margin: 0;
			padding: 0;
		}
	}

	.content .column.two {
		@include make-col(12);

		@include media-breakpoint-up(md) {
			@include make-col(9);
		}
	}

	.sidebar-filter {
		font-size: 14px;

		.form-group {
			margin-bottom: 1.5rem;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}

		.heading {
			display: none;
			font-size: 20px;
		}

		.close-btn {
			display: none;
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 24px;
			line-height: 1;
		}

		&.open {
			display: block;
			position: fixed;
			z-index: 999;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			max-width: 360px;
			height: 100%;
			padding: 20px 20px 75px 20px;
			overflow-x: hidden;
			background-color: $white;

			.heading,
			.close-btn {
				display: block;
			}
		}
	}

	.page_block {
		&.grid {
			justify-content: flex-start;

			.item {
				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				@include media-breakpoint-up(xl) {
					@include make-col(4);
				}
			}
		}
	}

	.fixed-filter-button {
		@include media-breakpoint-up(md) {
			display: none;
		}

		position: fixed;
		z-index: 998;
		left: 0;
		right: 0;
		bottom: 30px;
		text-align: center;

		.btn {
			min-width: 230px;
			margin: 0 auto;
			padding: 0.7rem 1rem;
			border-radius: 5px;
			background-color: $primary;
			border-color: $white;
			box-shadow: 0px 3px 6px $box-shadow;
			justify-content: center;

			i {
				margin-right: 10px;
			}
		}
	}

	.card-assortiment {
		.card-caption {
			.card-subtitle {
				font-weight: 400;
			}
		}

		.card-extra-label {
			color: $orange;
			font-weight: 700;
			margin-bottom: .5rem;
			margin-top: auto;

			+ .card-buttons {
				margin-top: 0 !important;
			}
		}
	}
}

&.assortiment_detail {
	.assortiment_detail {
		margin-top: 60px;

		.btn-back {
			display: inline-block;
		}

		.prices {
			display: inline-block;
			margin-bottom: 30px;
			padding: 10px 15px;
			font-size: 20px;
			font-weight: 700;

			.price {
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				.price-value {
					margin-right: 10px;
				}

				&.discount {
					.price-description {
						font-size: 16px;
					}
				}

				&.discounted {
					font-size: 14px;
					text-decoration: line-through;
				}
			}
		}

		h3 {
			&::after {
				content: "";
				display: block;
				max-width: 60px;
				height: 2px;
				margin: 5px 0 15px 0;
			}
		}

		.description {
			margin: 30px 0;
		}

		.characteristics {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					flex-wrap: wrap;

					.label {
						flex-basis: 50%;
					}
				}
			}
		}

		.downloads {
			margin: 30px 0;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					flex-wrap: wrap;

					.label {
						flex-basis: 50%;
					}
				}
			}
		}

		.contact-block {
			margin-top: 30px;
			padding: 30px;

			h2 {
				text-transform: none;
			}
		}
	}
}

.assortiment-section {
	background: $primary;

	.container-default {
		@extend .container;

		.container-holder {
			position: relative;
			display: flex;
			justify-content: center;

			h2 {
				z-index: 10;
				position: absolute;
				top: -23px;
				left: auto;
				right: auto;
				margin-bottom: 0;
				padding: 10px 45px 5px 45px;
				background: $secondary;
				border-radius: 4px;
				color: $white;
				font-size: 20px;
				@include media-breakpoint-up(md) {
					font-size: 26px;
				}
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;

				strong {
					display: inline-block;
					font-weight: 600;
					background: url('/images/line-white.svg') no-repeat center bottom;
				}

				&::before {
					content: "";
					z-index: 1;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					width: 0;
					height: 0;
					margin: 0 auto;
					border-top: 15px solid $secondary;
					border-left: 15px solid transparent;
					border-right: 15px solid transparent;
				}
			}

			.mini_filter {
				padding: 70px 0 45px 0;
				@media (max-width: 479px) {
					padding-top: 100px;
				}

				form {
					.form-group {
						.form-control {
							background: $white;
							border-color: $white;
							border-radius: 4px;
							color: $primary;
							font-size: 14px;
							font-weight: 500;

							&::-webkit-input-placeholder {
								color: $primary;
							}

							&:-ms-input-placeholder {
								color: $primary;
							}

							&::-moz-placeholder {
								color: $primary;
								opacity: 1;
							}
						}

						.submit_button {
							.btn {
								padding-top: 6px;
								padding-bottom: 6px;
								background: $blue-dark;
								border-color: $blue-dark;
								border-radius: 4px;
								color: $white;
								font-size: 14px;
								font-weight: 700;
								text-transform: uppercase;
								justify-content: center;
								i {
									margin-left: 15px;
									font-size: 20px;
								}
								&:hover {
									background: $secondary;
									border-color: $secondary;
								}
							}
						}
					}
				}
			}
		}
	}
}
